import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    $('.modoui_inline_help').tooltip(
      {
        html: false,
        animation: true,
        trigger: 'hover focus'
      }
    );
  }

}
